@font-face {
    font-family: 'montserrat-light';
    src: url('../fonts/montserrat-light.otf') format('opentype');
}

@font-face {
    font-family: 'montserrat-semibold';
    src: url('../fonts/montserrat-semibold.otf') format('opentype');
}

@font-face {
    font-family: 'montserrat-regular';
    src: url('../fonts/montserrat-regular.otf') format('opentype');
}