// custom modal

.modal {
   padding: 0 !important;
}

.modal-dialog-center {
  margin-bottom: 0 !important;

  max-width: initial;
  max-height: initial;
  height: 100%;
  margin: 0;

 
  & .modal-content {
    border: none;
    background: transparent;
    width: 100%;
    height: 100%;
  }

  & .modal-body {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.fade-scale {
  transform: scale(0.85);
  opacity: 0;
  -webkit-transition: all .35s linear;
  -o-transition: all .35s linear;
  transition: all .35s linear;
}


.fade-scale.show {
  opacity: 1;
  transform: scale(1);
}

