// TABS
.nav-pills .nav-link, .nav-pills > .nav-link {
  color: inherit;
  text-decoration: none;
  font-size: 1.2rem;
  border: px_to_rem(1.5px) solid transparent;
  font-family: "Montserrat-Light";

  &.active, & .show {
    color: $yellow;
    background: transparent;
    border: px_to_rem(1.5px) solid $yellow;
  }

  &:hover {
    color: $yellow;
  }
}

#portfolio {
  padding-top: px_to_rem(50px);

  & > div.row:first-child {
    padding: 0;
  }
  font-family: "Montserrat-Light";

  @media only screen and (min-width: 1200px) and (max-width: 1366px) {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100%;
  }

  // Medium devices (tablets, 768px and up)
  @media (max-width: 1200px) {
    & > div.row {
      padding: 0;
   }
  }
}

.card {
  height: 200px;
  color: $white;
  cursor: pointer;
  //border: px_to_rem(1.5px) solid transparent;
  //border: none;

  transition: transform .3s ease-in-out;

  // this prevents from content jittering when animation is running
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);

  &:hover {
    transform: translateY(-10px);
    border-radius: 0px;
    box-shadow: 0px 2px 3px 1px rgba(161,161,161, 0.4);
    // border: px_to_rem(1.5px) solid $white;
  }


// Medium devices (tablets, 768px and up)
  @media (max-width: 768px) {
    height: auto;
    //max-height: 400px;
  }

  //// NOTE: this is just for positioning of card-content vertically because parent container .card-detail
  ////       for some reason does not take whole height of its children - probably because of many flex properties
  //& #card-content-wrapper {
  //  width: 100vw;
  //  height: 100vh;
  //  overflow: hidden;
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //}

  & div.card-content {
    display: flex;
    height: 100%;

    @media (max-width: 768px) {
      flex-wrap: wrap;  // wraps navigation and card sections
    }
  }

  & div.card-content > div.card-content-left {
    height: auto;
    background: $dark-gray;
    flex: 1 1 40%;  //grow shrink basis  --> it cant grow
    padding: 1.25rem;

    // Medium devices (tablets, 768px and up)
    @media (max-width: 768px) {
      flex-basis: 100%;
      order: 2;
    }
  }

  & div.card-content > div.card-content-right {
    height: 100%;
    flex: 1 0 60%;  //grow shrink basis --> it cant shrink, this is image

    // Medium devices (tablets, 768px and up)
    @media (max-width: 768px) {
      flex-basis: 100%;
      order: 1;
    }
  }

  & div.card-content div.card-body {
    height: 100%;
    display: block;
    //flex-direction: column;
    //justify-content: space-between;
    padding: 0 !important;
    position: relative;

    // Medium devices (tablets, 768px and up)
    @media (max-width: 768px) {
      margin: auto;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      width: auto;
    }
  }

  & div.card-body > h5 {
    font-size: 1.2rem;
    width: 100%;
  }

  & div.card-body > button.card-more-btn {
    width: 120px;
    padding: px_to_rem(7px);
    text-align: right;
    font-size: 1.1rem;
    background: transparent;
    color: $white;
    position: absolute;
    bottom: 0;

    outline: none;
    border: none;
    border: px_to_rem(1.5px) solid $white;

    transition: background-color .2s ease-in-out;

    // Medium devices (tablets, 768px and up)
    @media (max-width: 768px) {
      text-align: center;
      margin: auto;
      position: initial;
    }
  }

  // NOTE: this is hover on whole .card
  &:hover div.card-body > button.card-more-btn {  // div.card-body > button.card-more-btn
    background-color: $white;
    color: $dark-gray;
    cursor: inherit;
  }

  & img {
    height: 100%;
    max-height: 200px;
    object-fit: cover;
  }
}

.card-white {
  @extend .card;

  color: $dark-gray;
  border: px_to_rem(1.5px) solid $lighter-gray;

  &:hover {
    // border: px_to_rem(1.5px) solid $dark-gray;
  }

  & div.card-content > div.card-content-left {
    background: $white;
  }

  & div.card-body > button.card-more-btn {
    background: transparent;
    color: $dark-gray;
    border: px_to_rem(1.5px) solid $lighter-gray;
  }

  // NOTE: this is hover on whole .card
  &:hover div.card-body > button.card-more-btn {
    background-color: $dark-gray;
    color: $white;
  }
}

.card-detail {
  @extend .card;
  height: 100%;
  width: 90%;

  @media (max-width: 1200px) {
    width: 100%;
    height: auto;
  }

  @media (max-width: 990px) {
    width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }

  @media (max-width: 580px) {
    width: 100%;
    height: auto;
  }

  // disable effects
  transition: none !important;
  transform : none !important;
  box-shadow: none !important;
  border: none !important;
  cursor: auto;  // normal cursor

  & div.card-body {
    padding-top: 0;
    padding: 1.4rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  & div.card-body > h5 {
    font-size: 2.5rem;
    width: 100%;
    font-weight: lighter;

    @media (max-width: 990px) {
      font-size: 2rem;
    }
  }

  & div.card-controls {
    flex: 1 1 100%;
    font-size: 1.9rem;
    width: 100%;
    order: 0;
  }

  & div.card-controls > div.card-controls-left > div, div.card-controls > div.card-controls-right > div {
    cursor: pointer;
  }

  & div.card-controls > div.card-controls-left {
    flex: 1 1 37%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & div.card-controls > div.card-controls-right {
    flex: 1 0 63%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & div.card-content {
    flex-wrap: wrap;
  }

  & div.card-content > div.card-content-left {
    flex: 1 1 37%;  //grow shrink basis  --> it cant grow

    // Medium devices (tablets, 768px and up)
    @media (max-width: 768px) {
      height: auto;
      flex-basis: 100%;
      order: 2;
    }
  }

  & div.card-content > div.card-content-right {
    flex: 1 0 63%;  //grow shrink basis --> it cant shrink, this is image

    // Medium devices (tablets, 768px and up)
    @media (max-width: 768px) {
      flex-basis: 100%;
      order: 1;
    }
  }

  & img {
    // reset max height
    height: 100%;
    max-height: initial;  // IMPORTANT - this must be here because of some bootstrap stuff, otherwise images does not
                          // have full height

    @media (max-width: 768px) {
      height: 100%;
      //max-height: 328px;  // NOTE: last height when card is horizontal
      flex-basis: 100%;
      order: 1;
    }

    object-fit: cover;
  }
}

.card-detail-white {
  @extend .card-white;
  @extend .card-detail
}


// manually controll width of columns since @include make-col(6) does not work
@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .tab-content .tab-pane .row > div  {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
