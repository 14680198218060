.footer-cont {
    float: none;
    display: flex;
    flex-wrap: wrap;
    font-weight: lighter;
}

.footer-cont .text-left {
    flex: 1 1 auto;
    text-align: left !important;

    @media (max-width: 900px) {
      flex-basis: 100%;
      text-align: center !important;
    }
}

.footer-cont .text-right {
    flex: 1 1 auto;
    text-align: right !important;

    @media (max-width: 900px) {
      flex-basis: 100%;
      text-align: center !important;
    }
}