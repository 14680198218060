#gallery {
  width: 100%;
  height: 100%;

  & .carousel-inner {
    width: 100%;
    height: 100%;
  }

  & .carousel-inner .carousel-item {
    width: 100%;
    height: 100%;
  }

  & .carousel-indicators {
    margin-bottom: 0;
  }

  & .carousel-indicators li{
    margin-bottom: 0;
    width: auto;
    height: auto;
    font-family: "Montserrat-Regular";

    text-indent: unset;
    color: $white;
    background-color: unset;
    font-size: 1.45rem;
    letter-spacing: 0.02rem;
  }

  & .carousel-indicators li.active{
    font-family: "Montserrat-SemiBold";
  }
}