 //NOTE: thjs is just a fake first image, which serves as placeholder, because
 //      camera.js plugin has support nothing like that so far

#first-img-placeholder::before {
    content: '';
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    position: absolute;
    z-index: 25;
}

 #first-img-placeholder {
   width: 100vw !important;
   height: 100vh !important;
   background-image: url("../img/placeholders/slide01.jpg");
   position: absolute;
   z-index: 20;
   opacity: 0;
   transition: opacity 1000ms ease;
 }

 .visible {
   opacity: 1;
 }
