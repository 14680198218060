// CONTAINER

.st-icon-cont {
  width: 30px;
  height: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

// BASE
.st-icon-base {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


// ARROW LEFT

.st-arrow-left {
  @extend .st-icon-base;
  height: 2px;
  background-color: $white;
}

.st-arrow-left:before {
  content: '';
  border: 1px solid $white;
  width: 20px;
  transform: translateY(-6px) translateX(-8px) rotateZ(-40deg);
  position: absolute;  // NOTE: it must be absolute, otherwise it takes only half of width, because share line with :after
}

.st-arrow-left:after {
  content: '';
  border: 1px solid $white;
  width: 20px;
  transform: translateY(6px) translateX(-8px) rotateZ(40deg);
  position: absolute;
}

// ARROW RIGHT

.st-arrow-right {
  @extend .st-icon-base;
  height: 2px;
  background-color: $white;
}

.st-arrow-right:before {
  content: '';
  border: 1px solid $white;
  width: 20px;
  transform: translateY(6px) translateX(8px) rotateZ(-40deg);
  position: absolute;
}

.st-arrow-right:after {
  content: '';
  border: 1px solid $white;
  width: 20px;
  transform: translateY(-6px) translateX(8px) rotateZ(40deg);
  position: absolute;
}

// CROSS

.st-cross {
  @extend .st-icon-base;
}

.st-cross:before {
  content: '';
  border: 1px solid $white;
  position: absolute;
  width: 30px;

  transform: rotateZ(45deg);
}

.st-cross:after {
  content: '';
  border: 1px solid $white;
  position: absolute;
  width: 30px;

  transform: rotateZ(-45deg);
}